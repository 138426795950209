<template>
  <div class="play-seeker">
    <div class="play-seeker__video-js">
      <video ref="videoJs" class="video-js" />
    </div>
    <div class="play-seeker__controls">
      <div class="play-seeker__controls__jump" @click="onBefore">
        <div class="play-seeker__controls__jump__icon">
          <img src="/icons/icn-player-back-green.svg" alt="" />
        </div>
        <div>Anterior</div>
      </div>
      <div class="play-seeker__controls__jump" @click="onNext">
        <div>Siguiente</div>
        <div class="play-seeker__controls__jump__icon">
          <img src="/icons/icn-player-next-green.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import VideoJS from 'video.js';
// eslint-disable-next-line no-unused-vars
import offset from 'videojs-offset/dist/videojs-offset';
import axios from 'axios';

function getPlayIndexes() {
  const playProps = [
    'playId', // number
    'leagueName', // string
    'seasonName', // number
    'gameId', // number
    'playerName', // string
    'refereeName', // string
    'stadiumName', // string
    'finalScore', // string
    'scoreAtMomentOfPlay', // string
    'gameDate', // string
    'homeTeamName', // string
    'homeTeamLogo', // string
    'awayTeamName', // string
    'awayTeamLogo', // string
    'categoryId', // number
    'categoryName', // string
    'startTime', // number
    'endTime', // number
    'minuteOfPlay', // number
    'videoUrl', // string
  ];
  return playProps.reduce((accumulator, propName, index) => {
    accumulator[propName] = index;
    return accumulator;
  }, {});
}

export default {
  name: 'PlaySeeker',
  data() {
    return {
      videoJs: null,
      isVideoJsReady: false,
      playIndexes: getPlayIndexes(),
      lastPlayGameId: 0,
      currentPlayIndex: 0,
      baseUrl: 'https://golstatsbets.blob.core.windows.net/video-player',
      plays: [],
      playerQueue: [],
      playInfo: {},
      userBehaviorUrl: 'https://oslnue9oac.execute-api.us-east-2.amazonaws.com/qa/user-behavior',
      firstInteractionUrl: 'https://rhev2kbe0a.execute-api.us-east-2.amazonaws.com/qa/interactions/andressamanop',
      geoOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      coords: null,
      timeLapses: [],
      timeUpdates: [],
    };
  },
  watch: {
    playerQueue(newValue) {
      if (newValue && newValue.length) {
        this.setPlay();
      }
    },
  },
  methods: {
    async fetchInitialList() {
      try {
        const response = await axios.get(this.firstInteractionUrl);
        if (response.data && response.data.data) {
          this.formatPlays(response.data.data);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    setPlay() {
      if (this.isVideoJsReady) {
        const gamePlay = this.playerQueue[this.currentPlayIndex];
        if (this.lastPlayGameId !== gamePlay.gameId) {
          this.lastPlayGameId = gamePlay.gameId;
          this.videoJs.src([{ src: `${this.baseUrl}/${gamePlay.gameId}_1.mp4` }]);
        }
        this.timeLapses = [];
        this.timeUpdates = [];
        this.videoJs.offset({
          start: gamePlay.startSeconds,
          end: gamePlay.endSeconds,
        });
        this.videoJs.currentTime(0);
        setTimeout(() => {
          this.videoJs.play();
        }, 1000);
      }
    },
    /**
     *
     * @param {Object} listData
     * @param {String} listData.group_playlist_id
     * @param {String} listData.playlist_id
     * @param {String} listData.user_id
     * @param {String} listData.group_id
     * @param {String} listData.created_at
     * @param {Number} listData.total_plays
     * @param {Array} listData.playlist
     * @param {Boolean} mergePlays
     */
    formatPlays(listData, mergePlays = false) {
      if (listData && listData.playlist && listData.playlist.length) {
        const formattedPlays = listData.playlist.map((play, index) => {
          play[this.playIndexes.endTime] = play[this.playIndexes.startTime] + 50;
          return {
            gameId: play[this.playIndexes.gameId],
            startSeconds: play[this.playIndexes.startTime],
            endSeconds: play[this.playIndexes.endTime],
            totalSeconds: Math.floor(play[this.playIndexes.endTime] - play[this.playIndexes.startTime]),
            payloadData: {
              play_id: play[this.playIndexes.playId],
              total_plays: listData.total_plays,
              play_number: index,
              group_playlist_id: listData.group_playlist_id,
              playlist_id: listData.playlist_id,
              group_id: listData.group_id,
              moment_of_play: play[this.playIndexes.minuteOfPlay],
              play_type: play[this.playIndexes.categoryId],
              viewer_second_start: Math.floor(play[this.playIndexes.startTime]),
              intended_second_start: Math.floor(play[this.playIndexes.startTime]),
              intended_second_end: Math.floor(play[this.playIndexes.endTime]),
              latitude_of_user: null,
              longitude_of_user: null,
            },
          };
        });
        if (mergePlays) {
          // todo: merge plays
          this.playerQueue = [...this.playerQueue.slice(0, this.currentPlayIndex + 1), ...formattedPlays];
        } else {
          this.playerQueue = formattedPlays;
        }
      }
    },
    onPlaylistEnded() {},
    onPlayChanged(play) {
      this.playInfo = {
        date: play.gameDate,
        playerName: play.player.name,
        playCategory: play.categoryName,
        teams: `${play.game.homeTeam.name} vs ${play.game.awayTeam.name}`,
        playerPhoto: play.player.photo,
        teamLogo: play.playerTeamLogo,
        teamId: play.teamId,
        categoryId: play.categoryId,
        jersey: play.jersey,
        mainCategoryName: play.mainCategory.name,
        categoriesNames: play.categoriesNames,
      };
    },
    onPlayerModeClicked(modeId) {
      this.playerModeSelected = modeId;
    },
    getPlayerOptions() {
      return {
        // aspectRatio: this.data.aspect_ratio,
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        controls: true,
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'playbackRateMenuButton',
            'CurrentTimeDisplay',
            'progressControl',
            'remainingTimeDisplay',
            'fullscreenToggle',
          ],
          pictureInPictureToggle: false,
          volumePanel: {
            inline: false,
          },
        },
        liveui: true,
        // poster: this.data.poster,
        preload: 'auto',
        fluid: true,
        // sources: this.data.video_link,
      };
    },
    /**
     *
     * @param {number} currentPlayIndex
     * @param {number} userAction - values can be: 0 = next clicked, 1 = play ended, 2 = before clicked
     * @param {string} dateTimeIsoString
     * @param {Array<number>} timeLapses
     */
    async sendUserBehavior(currentPlayIndex, userAction, dateTimeIsoString, timeLapses) {
      try {
        const currentPlay = this.playerQueue[currentPlayIndex];
        const payload = currentPlay.payloadData;
        if (currentPlay) {
          payload.action = userAction;
          payload.percentage_viewed = this.getPercentageViewed(currentPlay.totalSeconds, timeLapses);
          payload.viewer_second_end = Math.floor(payload.viewer_second_start + this.timeLapses.pop());
          payload.date_time_of_user_action = dateTimeIsoString;
          if (this.coords) {
            payload.latitude_of_user = this.coords.latitude;
            payload.longitude_of_user = this.coords.latitude;
          }
        }
        const response = await axios.post(
          'https://oslnue9oac.execute-api.us-east-2.amazonaws.com/qa/user-behavior',
          payload,
        );
        if (response.status === 200 && response.data && response.data.data) {
          this.formatPlays(response.data.data, true);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    /**
     *
     * @param {number} totalSeconds
     * @param {Array<Number>} timeLapses
     */
    getPercentageViewed(totalSeconds, timeLapses) {
      const totalSecondsArray = Array.from({ length: totalSeconds }, () => 0);
      timeLapses.reverse();
      let percentageViewed = 0;
      // const lastSecondViewed = timeLapses.pop();
      if (totalSeconds) {
        let garbageSeconds = 0;
        for (let i = 0; i < timeLapses.length - 1; ) {
          const lapseEnded = Math.floor(timeLapses[i]);
          const lapseStarted = Math.floor(timeLapses[i + 1]);
          for (let j = lapseStarted; j <= lapseEnded; j++) {
            totalSecondsArray[j] = 1;
          }
          garbageSeconds++;
          i = i + 2;
        }
        const secondsViewed = totalSecondsArray.reduce((accumulator, isSecondViewed) => {
          return accumulator + isSecondViewed;
        }, 0);
        percentageViewed = Math.ceil(((secondsViewed - garbageSeconds) / totalSeconds) * 100);
      }
      // percentageViewed = Math.floor((lastSecondViewed / totalSeconds) * 100);
      return percentageViewed;
    },
    onVideoJsReady() {
      this.isVideoJsReady = true;
    },
    onNext(isPlayEnded = false) {
      this.videoJs.pause();
      setTimeout(() => {
        if (this.currentPlayIndex < this.playerQueue.length) {
          this.sendUserBehavior(this.currentPlayIndex, isPlayEnded ? 1 : 0, new Date().toISOString(), [
            ...this.timeLapses,
          ]);
          this.currentPlayIndex++;
          this.setPlay();
        }
      }, 500);
    },
    onBefore() {
      this.videoJs.pause();
      setTimeout(() => {
        if (this.currentPlayIndex > 0) {
          this.sendUserBehavior(this.currentPlayIndex, 2, new Date().toISOString(), [...this.timeLapses]);
          this.currentPlayIndex--;
          this.setPlay();
        }
      }, 500);
    },
    onPlayEnded() {
      this.onNext(true);
    },
    onPlay() {
      this.timeLapses.push(this.videoJs.currentTime());
    },
    onPause() {
      if (this.timeLapses.length) {
        this.timeLapses.push(this.timeUpdates[this.timeUpdates.length - 2]);
      }
    },
    onGeoSuccess($event) {
      this.coords = $event.coords;
    },
    onGeoError(error) {
      throw new Error(error);
    },
    onLoadedMetadata() {
      this.videoJs.currentTime(0);
    },
    onTimeUpdate() {
      this.timeUpdates.push(this.videoJs.currentTime());
    },
  },
  created() {
    navigator.geolocation.getCurrentPosition(this.onGeoSuccess, this.onGeoError, this.geoOptions);
    this.fetchInitialList();
  },
  mounted() {
    this.videoJs = new VideoJS(this.$refs.videoJs, this.getPlayerOptions(), this.onVideoJsReady);
    this.videoJs.on('play', this.onPlay);
    this.videoJs.on('pause', this.onPause);
    this.videoJs.on('ended', this.onPlayEnded);
    this.videoJs.on('loadedmetadata', this.onLoadedMetadata);
    this.videoJs.on('timeupdate', this.onTimeUpdate);
    // this.videoJs.on('seeking', this.onSeeking);
    // this.videoJs.on('seeked', this.onSeeked);
  },
};
</script>

<style scoped lang="scss">
$blue: #132739;
@mixin icon-dimensions-450 {
  height: 32px;
  width: 32px;
}

.play-seeker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__video-js {
    width: 100%;
    max-width: 900px;
  }

  &__controls {
    margin-top: 1rem;
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;

    &__jump {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      color: white;
      font-size: 1.0625em;
      border-radius: 0.4705em;
      padding: 0.647em 0;
      font-family: Roboto-Medium, sans-serif;
      max-width: 10em;
      cursor: pointer;

      &__icon {
        @include icon-dimensions-450;

        & > img {
          @include icon-dimensions-450;
        }
      }
    }
  }
}
</style>
